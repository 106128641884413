<template>
  <div class="myheader">
    <div class="myheader_logo">

    </div>
    <div class="myheader_title">DEEPSHIKHA TRADING CO PVT LTD</div>
    <div class="menu">
      <div class="menuItem" v-for="item in routerList" :key="item.path"
        :class="currentPath == item.path ? 'activeItem' : ''" @click="handleItemClick(item.path)">
        {{item.label}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "top",

  data() {
    return {
      currentPath: this.$route.path,
      routerList: [
        {
          path: "/",
          label: "Home",
        },
        {
          path: "/services",
          label: "Service",
        },
        {
          path: '/aboutus',
          label: 'About'
        },
        {
          path: '/policies',
          label: 'Policies'
        },
        {
          path: '/contactinfo',
          label: 'Contact'
        },
        {
          path: '/info',
          label: 'T & C'
        },
        {
          path: '/deletion',
          label: 'Secruity'
        }
      ],
    };
  },

  watch: {
    $route(to) {
      this.currentPath = to.path;
    },
  },

  methods: {
    handleItemClick(path) {
      if (path == this.currentPath) {
        return;
      } else {
        this.$router.push(`${path}`);
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 1300px) {
  .myheader {
    display: none;
  }
}

.myheader {
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.myheader_logo {
  width: 40px;
  height: 40px;
  background: url(../assets/logo.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 5.71px;
  position: absolute;
  top: 20px;
  left: 160px;
}

.myheader_title {
  height: 28px;
  line-height: 28px;
  font-family: PingFang SC;
  color: #000000;
  font-size: 20px;
  position: absolute;
  top: 26px;
  left: 208px;
}

.menu {
  width: 120px;
  position: absolute;
  right: 160px;
  top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.menuItem {
  width: 120px;
  height: 42px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(73, 31, 1, 0.09);
  line-height: 42px;
  font-family: PingFang SC;
  font-weight: 700;
  color: #180000;
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
  cursor: pointer;
}

.activeItem {
  background: #ff7715 !important;
  color: #ffffff !important;
}
</style>
